import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import LocalFluid from "./fluid"

const ShortTestimonial = ({ shortVersion, location, user, avatar }) => (
  <div className="mb-30 col-xl-5 col-lg-6 col-md-10 d-flex" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
    <div className="d-flex radius10 block shadow">
      <div className="relative d-sm-flex align-items-stretch pl-35 pr-20 pt-35 pb-25 inner">
        {!avatar ? (
          undefined
        ) : (
          <LocalFluid src={avatar} className="mb-10 align-self-start radius10" style={{ minWidth: "70px", minHeight: "70px", maxWidth: "200px", margin: "0 auto" }} />
        )}
        <div className="relative ml-30 pb-35 info">
          <div className="f-18 medium text-adaptive">{documentToReactComponents(shortVersion.json)}</div>
          <div className="absolute f-14 semibold text-uppercase sp-20 op-3 author">
            {user}, {location}
          </div>
        </div>
      </div>
    </div>
  </div>
)

const Testimonials = ({ testimonials }) => (
  <section className="testimonial_6 bg-dark pt-105 pb-75 color-white text-center text-sm-left">
    <div className="container px-xl-0">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-10" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
          <h2 className="mb-35 small">Our Happy Clients</h2>
        </div>
      </div>
      <div className="row align-items-stretch justify-content-center">
        {testimonials.map(t => (
          <ShortTestimonial key={t.id} {...t} />
        ))}
      </div>
    </div>
  </section>
)

export default Testimonials
