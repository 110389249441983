import React from "react"

export default () => (
  <section className="content_31 bg-dark pt-100 pb-70 color-white">
    <div className="container px-xl-0">
      <div className="row justify-content-center text-center">
        <h2 className="small col-lg-8" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
          Our gear
        </h2>
        <div className="col-lg-8 mt-25 f-18 medium op-7 text-adaptive">&nbsp;</div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-3 col-md-5 col-sm-6" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
          <a href={"https://elysia.com"}>
            <div className="mx-auto mb-30 mw-270 h-160 d-flex justify-content-center align-items-center radius6 block shadow">
              <img src={require("../i/elysia-wdot.svg")} alt={"elysia"} />
            </div>
          </a>
        </div>
        <div className="col-lg-3 col-md-5 col-sm-6" data-aos-duration="600" data-aos="fade-down" data-aos-delay="150">
          <div className="mx-auto mb-30 mw-270 h-160 d-flex justify-content-center align-items-center radius6 block shadow">
            <img src={require("../i/telefunken.svg")} alt={"telefunken"} />
          </div>
        </div>
        <div className="col-lg-3 col-md-5 col-sm-6" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
          <a href={"http://gyraf.dk/"}>
            <div className="mx-auto mb-30 mw-270 h-160 d-flex justify-content-center align-items-center radius6 block shadow">
              <img src={require("../i/gyraf.png")} alt={"gyraf"} />
            </div>
          </a>
        </div>
        <div className="col-lg-3 col-md-5 col-sm-6" data-aos-duration="600" data-aos="fade-down" data-aos-delay="450">
          <a href={"http://www.bettermaker.eu/"}>
            <div className="mx-auto mb-30 mw-270 h-160 d-flex justify-content-center align-items-center radius6 block shadow">
              <img src={require("../i/bettermaker.svg")} alt={"bettermaker"} />
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
)
