import React from "react"
import { Link } from "gatsby"

export default () => (
  <section className="feature_10 bg-light pb-70 text-center text-lg-left" id={"explainer"}>
    <div className="container px-xl-0">
      <div className="mt-80 hr h-2 d-none d-md-block" data-aos-duration="600" data-aos="fade-down" />
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-8 mb-40" data-aos-duration="600" data-aos="fade-down">
          <i className="fas fa-volume-up f-40 color-gray" />
          <div className="mt-25 mb-20 f-22 title">Real analog gear</div>
          <div className="color-heading text-adaptive mb-15">
            We use the same real analog EQs, compressors and tape machines that are used in <strong>best studios around the world.</strong>
          </div>
          <Link to="/products">View gear</Link>
        </div>
        <div className="col-lg-4 col-md-8 mb-40" data-aos-duration="600" data-aos="fade-down">
          <i className="fas fa-bullseye f-40 color-gray" />
          <div className="mt-25 mb-20 f-22 title">Full control</div>
          <div className="color-heading text-adaptive mb-15">
            You have total control over all of the aspects of using our gear. Our app <strong>streams audio directly to you in up to 192 kHz/24-bit</strong> while you tweak the
            knobs.
          </div>
          <Link to="/technology">About our technology</Link>
        </div>
        <div className="col-lg-4 col-md-8 mb-40" data-aos-duration="600" data-aos="fade-down">
          <i className="fas fa-gem f-40 color-gray" />
          <div className="mt-25 mb-20 f-22 title">Great price</div>
          <div className="color-heading text-adaptive mb-15">
            You can try Mixanalog for free. After that the price is much lower compared to renting studios that own such gear. You can{" "}
            <strong>save up to 95% of your budget when using Mixanalog.</strong>
          </div>
          <Link to="/#pricing">View plans</Link>
        </div>
      </div>
    </div>
  </section>
)
