export default [
  {
    id: "77535895-7478-5168-9a5c-534d4abb6add",
    name: "Pay as you go",
    // matPerMonth: 350,
    maximumSampleRate: 96,
    // monthlyPriceEUR: 13.5,
    // monthlyPriceUSD: 15,
    // preferred: false,
    storageAvailable: 5,
    // freeTierExtraMinutes: 0,
    // supportLevel: 1,
  },
  {
    id: "77535895-7478-5168-9a5c-534d4abb6adc",
    name: "Starter",
    matPerMonth: 350,
    maximumSampleRate: 96,
    monthlyPriceEUR: 13.5,
    monthlyPriceUSD: 15,
    preferred: false,
    storageAvailable: 5,
    freeTierExtraMinutes: 60,
    supportLevel: 1,
  },
  {
    id: "6e53374e-61d2-5779-80bf-ded4baeccc0f",
    name: "Producer",
    matPerMonth: 800,
    maximumSampleRate: 192,
    monthlyPriceEUR: 27,
    monthlyPriceUSD: 30,
    preferred: true,
    storageAvailable: 10,
    freeTierExtraMinutes: 135,
    supportLevel: 2,
  },
  {
    id: "70effd2e-095e-56c5-bdc1-c5510ebf9c56",
    name: "Pro",
    matPerMonth: 1800,
    maximumSampleRate: 192,
    monthlyPriceEUR: 54,
    monthlyPriceUSD: 60,
    preferred: false,
    storageAvailable: 20,
    freeTierExtraMinutes: 300,
    supportLevel: 3,
  },
]
