import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Carousel, { Modal, ModalGateway } from "react-images"

import AppCta from "./appCta"
import classnames from "classnames"

const HowToCategory = ({ name, description, images }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <div className="content_20_line" />
      <div className="ml-60 pt-10 block_inner" data-aos-duration="600" data-aos="fade-down">
        <div className="f-heading f-18 medium">{name}</div>
        <div className="color-heading text-adaptive">
          <div />
          <div className="d-sm-flex align-items-start video_block mb-25">
            <div className="text-adaptive">{documentToReactComponents(description.json)}</div>
          </div>
        </div>
      </div>
      <ModalGateway>
        {open ? (
          <Modal
            onClose={() => setOpen(false)}
            open={open}
            closeOnBackdropClick={true}
            closeOnEsc={true}
            styles={{
              dialog: (base) => ({
                ...base,
                maxHeight: "50vh",
                maxWidth: "50vw",
              }),
            }}
          >
            <Carousel
              views={images.map((i) => ({
                src: i.heroImage.localFile.childImageSharp.fluid.src,
                caption: documentToReactComponents(i.shortSummary.json),
              }))}
            />
          </Modal>
        ) : undefined}
      </ModalGateway>
    </>
  )
}

export default ({ howToCategories }) => (
  <section className="content_20 bg-light pt-105 pb-100">
    <div className="container px-xl-0">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-11">
          <h2 data-aos-duration="600" data-aos="fade-down">
            The workflow is easy
          </h2>
          <p>It only takes a couple of seconds and you are ready to go.</p>
          <div className="row mb-25">
            <div className="mt-25 col-lg-6 inner">
              <div className="relative block">
                {howToCategories.map((p, i) =>
                  !!i ? (
                    <HowToCategory key={p.id} id={p.id} {...p} />
                  ) : (
                    <div key={p.id} className={classnames("relative block")}>
                      <HowToCategory id={p.id} {...p} />
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <img srcSet={`${require("../i/composition.png")} 2x`} alt={"composition"} style={{ width: "500px", height: "auto", display: "block", margin: "auto" }} />
            </div>
          </div>
        </div>
        <div className="col-xl-10 col-lg-11">
          <div className="howToCtaWrap">
            <AppCta title="Sign up now" />
            <span>It's free</span>
          </div>
        </div>
      </div>
    </div>
  </section>
)
