import React from "react"
import classnames from "classnames"
import { Link } from "gatsby"
import AppCta from "./appCta"

class Plan extends React.Component {
  render() {
    const { name, index, monthlyPriceUSD, monthlyPriceEUR, preferred, showEur, raised, storageAvailable, maximumSampleRate, freeTierExtraMinutes } = this.props
    const showPrice = monthlyPriceEUR !== undefined && monthlyPriceUSD !== undefined
    return (
      <div className={"col-lg-3 mb-20 d-flex align-items-stretch order-0 order-lg-0"} data-aos-duration="600" data-aos="fade-down" data-aos-delay={index * 600}>
        <div className={classnames("mw-370 w-full mx-auto radius10 pt-35 pb-40 block", preferred && "bg-dark color-white", raised ? "shadow pricing-raised" : "shadow-sm")}>
          <div className="mb-35 f-14 semibold text-uppercase text-left ml-20 sp-20 title">{name}</div>
          <div className="row ml-30">
            <div className={classnames("col-auto text-left", preferred ? "color-gray" : "color-heading")}>
              <ul className="px-0">
                <li className={classnames("mb-10 bold", !freeTierExtraMinutes && "hidden")}>{freeTierExtraMinutes} minutes of studio time</li>
                <li className="mb-10">{storageAvailable} GB of space</li>
                <li>up to {maximumSampleRate} kHz sample rate</li>
              </ul>
            </div>
          </div>
          {showPrice && (
            <div className="d-flex align-items-center mt-25 ml-20">
              <div className="f-58 relative flex-shrink-0 price">
                <span className="absolute f-16">{showEur ? "€" : "$"}</span>
                <b>{showEur ? monthlyPriceEUR : monthlyPriceUSD}</b>
              </div>
              <div className="ml-15 text-left">
                per user
                <br />
                per month
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

class Pricing extends React.Component {
  state = { showEur: false, raised: this.props.plans.filter((p) => p.preferred)[0].id }

  render() {
    const { plans } = this.props
    const { showEur, raised } = this.state

    return (
      <section className="pricing_table_5 bg-light pt-105 text-center" id="pricing">
        <div className="container px-xl-0">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <h2 className="small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                Pricing
              </h2>
              <div className="mt-20 mb-65 color-heading text-adaptive" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
                You can pay as you go or subscribe for some extra benefits.
              </div>
            </div>
          </div>
          <div className="row flex-column flex-lg-row">
            {plans.map((p, i) => (
              <Plan key={p.id} index={i} {...p} showEur={showEur} raised={raised === p.id} />
            ))}
          </div>
          <div className={"row text-left mt-50"}>
            <div className={"col flexColumnCenter"}>
              <AppCta />
              <p className="mt-50">
                Have a look at our <Link to={"/faq"}>FAQ section</Link> for answers to most common questions or use the chat widget at the bottom right.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Pricing
