import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Explainer from "../components/explainer"
import HowToUse from "../components/howtouse"
import Partners from "../components/partners"
import CaseStudies from "../components/case-studies"
import Testimonials from "../components/testimonials"
import Pricing from "../components/pricing"
import ImageMeta from "../components/ImageMeta"

import howToCategories from "../data/howToCategories"
import plans from "../data/plans"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" meta={ImageMeta} />
    <Explainer />
    <Partners />
    <HowToUse howToCategories={howToCategories} />
    <Testimonials testimonials={data.testimonials.nodes} />
    <Pricing plans={plans} />
    <CaseStudies />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    plans: allContentfulPlan(sort: { order: [ASC], fields: [monthlyPriceUSD] }) {
      nodes {
        id
        name
        matPerMonth
        maximumSampleRate
        monthlyPriceEUR
        monthlyPriceUSD
        preferred
        storageAvailable
        freeTierExtraMinutes
        supportLevel
      }
    }
    testimonials: allContentfulTestimonial(filter: { byManufacturer: { ne: true }, heroic: { ne: true } }) {
      nodes {
        id
        user
        heroic
        location
        avatar {
          localFile {
            childImageSharp {
              fluid(maxWidth: 140) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        shortVersion {
          json
        }
      }
    }
    howToCategories: allContentfulHowToCategory(sort: { order: [ASC], fields: [priority] }) {
      nodes {
        id
        name
        priority
        description {
          json
        }
        heroImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 4096, base64Width: 64) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        images: howto_image {
          shortSummary {
            json
          }
          heroImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 4096, base64Width: 128) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
