import React from "react"
import { Link } from "gatsby"

export default () => (
  <section className="showcase_1 bg-light pt-105 pb-95">
    <div className="container px-xl-0">
      <div className="row justify-content-center text-center">
        <div className="col-xl-8 col-lg-10">
          <h2 className="small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
            Get the most out of analog gear
          </h2>
          <div className="mt-15 mb-30 f-22 color-heading text-adaptive description" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
            Join the revolution of sharing a cloud based studio with like-minded producers from all over the world working in all genres.
          </div>
          <div className="buttons" data-aos-duration="600" data-aos="fade-down" data-aos-delay="600">
            <Link to="/guide" className="mt-25 mx-15 btn btn-sm action-4 color-main">
              Download Mastering Guide
            </Link>
            <a
              href="https://www.youtube.com/playlist?list=PLgGwfN_nIr4iAA7bM-P8hUsPstyd_ubo5"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-25 mx-15 btn btn-sm action-4 color-main"
            >
              View tutorials
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
)
